<template>
  <div class="officeCard">
    <div class="officeCard-img adaptive-img">
      <img
        src="~/assets/img/jpg/office-girl.jpg"
        alt="office"
      >
    </div>
    <div class="officeCard-description">
      <div>
        <div
          v-for="(item, idx) in officeInfo"
          :key="`item-${idx}`"
          class="description-item"
        >
          <div
            v-if="item.link"
            class="description-item__link"
          >
            <img
              v-if="item.img"
              :src="item.img"
              alt="icon"
            >
            <NuxtLink :href="item.link">
              <span v-html="item.value"></span>
            </NuxtLink>
          </div>

          <div
            v-else
            v-html="item.value"
          >
          </div>
        </div>
      </div>

      <div class="description-item__schedule">
        <div class="subtitle">График работы:</div>
        <div class="schedule">
          <div
            v-for="scheduleItem in officeSchedule"
            :key="scheduleItem.label"
            class="schedule-item"
            :class="{
              'active': activeDay.day === scheduleItem.day,
              'dayoff':scheduleItem.day === 6 || scheduleItem.day === 7
            }"
            @click="onChangeActiveDay(scheduleItem)"
          >
            {{ scheduleItem.label }}
          </div>
        </div>
        <div class="time">{{ activeDay.workTime }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
export type OfficeInfo = {
	type?: 'email' | 'phone' | 'address';
	label: string;
	value: string;
	link: string;
	img?: string;
}
export type OfficeSchedule = {
	label: string;
	workTime: string;
	day: number;
}

const { officeInfo, officeSchedule } = defineProps<{
	officeInfo: OfficeInfo[],
	officeSchedule: OfficeSchedule[]
}>();

const activeDay = ref<OfficeSchedule>(officeSchedule[new Date().getDay() - 1] || {});

const onChangeActiveDay = (day: OfficeSchedule) => {
  activeDay.value = day;
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.officeCard {
	display: flex;
	background: var(--white);
	border-radius: 22px;
	padding: 15px;
	gap: 35px;
}

.officeCard-img {
	max-width: 180px;
	width: 100%;

	& > img {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 22px;
	}
}

.officeCard-description {
	display: flex;
	flex-direction: column;
}

.description-item__link {
	display: flex;
	margin-bottom: 10px;
	align-items: center;
	gap: 10px;

	& > img {
		width: 22px;
		height: 22px;
	}

	& > a {
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 140%; /* 25.2px */
		letter-spacing: -0.36px;
		color: var(--primary-80);
	}
}

.description-item {
	display: flex;
	gap: 10px;

	&:deep(.address) {
		color: var(--primary);
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 23px;
		letter-spacing: -0.294px;
		margin-bottom: 20px;
		display: inline-block;
	}

	& > a {
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 140%; /* 25.2px */
		letter-spacing: -0.36px;
		color: var(--primary-80);
	}

	& > a:hover {
		text-decoration: underline;
	}
}

.description-item__schedule {
	margin-top: 20px;

	& > .subtitle {
		color: var(--primary-60);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 130%; /* 20.8px */
		letter-spacing: -0.32px;
		margin-bottom: 5px;
	}

	& > .schedule {
		display: flex;
		gap: 5px;
		margin-bottom: 4px;
	}

	& .schedule-item {
		width: 33px;
		height: 33px;
		color: var(--primary-80);
		font-weight: 400;
		font-size: 14px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		border-radius: 8px;
		border: 1px solid transparent;
		transition: border-color .2s linear;

		&.dayoff {
			color: var(--primary-20);

			&.active {
				color: var(--primary);
			}
		}

		&:hover {
			border-color: var(--secondary);
		}

		&.active {
			font-weight: 600;
			background: var(--secondary);
		}
	}

	& > .time {
		color: var(--primary-80);
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 130%; /* 23.4px */
		letter-spacing: -0.36px;
	}
}

@include media('xl') {
	.officeCard {
		flex-direction: column;
	}

	.officeCard-img {
		max-width: initial;
	}
}

@include media('md') {
	.officeCard {
		flex-direction: row;
		padding: 12px;
		gap: 8px;
	}

	.officeCard-img {
		width: 85px;
		min-width: 85px;
		border-radius: 12px;
		padding: 0;
	}

	.officeCard-description {
		gap: 12px;
	}

	.description-item__link {
		gap: 2px;

		& > img {
			width: 20px;
			height: 20px;
		}

		& > a {
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 130%; /* 18.2px */
			letter-spacing: -0.28px;
			color: var(--primary-80);
		}
	}

	.description-item {
		& > .subtitle {
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 120%; /* 21.382px */
			letter-spacing: -0.24px;
			margin-bottom: 0;
		}

		&:deep(.address) {
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 130%; /* 18.2px */
			letter-spacing: -0.28px;
			margin-bottom: 15px;
		}
	}

	.description-item__schedule {
		display: none;

		& > .schedule {
			gap: 0;
		}

		& .schedule-item {
			width: 27px;
			height: 27px;
			font-size: 12.344px;
			font-style: normal;
			font-weight: 600;
			line-height: 130%; /* 16.047px */
			letter-spacing: -0.247px;
		}
	}
}
</style>
